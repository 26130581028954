import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'name',
    'placeId',
    'autocompleteContainer',
    'searchInput'
  ];

  async connect() {
    const {
      Place,
      AutocompleteSessionToken,
      AutocompleteSuggestion
    } = await this.loadGoogleMaps();

    this.Place = Place;
    this.AutocompleteSuggestion = AutocompleteSuggestion;
    this.AutocompleteSessionToken = AutocompleteSessionToken;
  }

  async loadGoogleMaps() {
    if (typeof google === 'undefined') {
      throw new Error("Missing .env entry for GOOGLE_MAPS_API_KEY");
    }

    return await google.maps.importLibrary("places");
  }

  async search(event) {
    this.clearSuggestions();

    if (event.target.value === '') {
      return;
    }

    if (!this.sessionToken) {
      this.sessionToken = new this.AutocompleteSessionToken();
    }

    const query = event.target.value;

    const { suggestions } = await this.AutocompleteSuggestion.fetchAutocompleteSuggestions(
      this.makeRequest(query)
    );

    this.placesById = suggestions.reduce((acc, suggestion) => ({
      ...acc,
      [suggestion.placePrediction.placeId]: suggestion.placePrediction
    }), {});

    for (const suggestion of suggestions) {
      const { placePrediction } = suggestion;

      this.appendSuggestion(placePrediction);
    }

    // If we're showing autocomplete results, make sure those results are visible
    this.ensureResultsVisible();
  }

  makeRequest(query) {
    return {
      input: query,
      sessionToken: this.sessionToken,
      language: 'en-US',
      region: 'us'
    };
  }

  async selectSuggestion(event) {
    const { params } = event;
    const { placeId } = params;

    const place = this.placesById[placeId].toPlace();
    await place.fetchFields({
      fields: ['displayName', 'formattedAddress', "addressComponents"]
    })

    this.searchInputTarget.value = place.formattedAddress;
    this.nameTarget.value = place.formattedAddress;
    this.placeIdTarget.value = placeId;
  }

  clearSuggestions() {
    this.autocompleteContainerTarget.innerHTML = '';
  }

  appendSuggestion(placePrediction) {
    this.autocompleteContainerTarget.insertAdjacentHTML(
      'beforeend',
      `<li class="dropdown-item semibold xs cursor-pointer"
        title="${placePrediction.text.text}"
        data-action="click->address-search#selectSuggestion"
        data-address-search-place-id-param="${placePrediction.placeId}"
        >
        ${placePrediction.text.text} 
       </li>`
    );
  }

  ensureResultsVisible() {
    if (this.autocompleteContainerTarget.classList.contains('d-none')) {
      this.autocompleteContainerTarget.classList.remove('d-none');
    }
  }
};